/* eslint-disable import/prefer-default-export */

import md5 from 'md5';

/**
 * Call our `subscription-add` Firebase Function.
 *
 * @param {string} [firstName] The first name, optional.
 * @param {string} email The email address.
 * @param {string} collectorId The collector identifier.
 */
export async function addSubscription(firstName, email, collectorId) {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  const hash = md5(`${email}/${firstName}/${collectorId}`);
  const body = {
    firstName,
    email,
    collectorId,
    hash,
  };

  const request = new Request(
    'https://europe-west2-silk-helix.cloudfunctions.net/subscription-add',
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    },
  );

  const response = await fetch(request);
  const { status } = response;
  if (status === 201) {
    return; // Success (Created)
  }

  const { message } = await response.json();

  const fallbackMessage = 'Service call failed without returning an explanatory message.';
  throw new Error(`${messageOrFallback(message, fallbackMessage)} [Status: ${status}]`);
}

function messageOrFallback(message, fallbackMessage) {
  if (!message) return fallbackMessage;
  if (typeof message === 'string' || message instanceof String) {
    return message.trim().length > 0 ? message : fallbackMessage;
  }
  return fallbackMessage;
}
