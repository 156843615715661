import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'amazon-connect-streams';

/* eslint-disable no-console, no-shadow */

function Component() {
  const ccpContainer = useRef(null);

  // It's worth documenting the implications of `contacts` and `setContacts` both being constant for the scope of this function:
  // - contacts: Given that the primary goal of this function is to return a static visual state for this component, from this
  //   point forwards in this function the value of contacts is the state of this Map for this static visual state. This function
  //   gets called afresh by the React framework each time it knows that state has changed - and, thus, the static visual state
  //   needs to change. We MUST NOT use the contacts variable in any callback handler - it can only be used to contribute towards
  //   the static visual state returned by this function.
  // - setContacts: Is a function and our escape hatch from this function's responsibilities for handling the single, static visual
  //   state for this particular execution of this Component function. We MUST use the calling style for setContacts where we pass
  //   it a function to call, enabling it to supply us the current value of contacts to use as our base for further mutation - that
  //   is `setContacts((previousContacts) => { });`, returning the new value for contacts.
  const [contacts, setContacts] = useState(new Map());

  // We pass an empty array to the second argument to the useEffect hook call. This is very important. It means that useEffect will
  // only run our function once for this Component, no matter how many times the state changes going forwards.
  useEffect(() => {
    console.log('Calling connect.core.initCCP...');

    // Add some classes to the document body element, which we remove in the cleanUp() function we return from
    // this effect function.
    const bodyClassNames = ['noScrollbars'];
    bodyClassNames.forEach((className) => document.body.classList.add(className));

    // eslint-disable-next-line no-undef
    const ccpApi = connect; // defined so we only need to disable ESLint's no-undef for this one line

    // We were seeing LOG and INFO messages in the console log, which made it very noisy.
    // https://github.com/amazon-connect/amazon-connect-streams/blob/HEAD/Documentation.md#loglevel
    const ccpLog = ccpApi.getLog();
    const ccpWarn = ccpApi.LogLevel.WARN;
    ccpLog.setLogLevel(ccpWarn);
    ccpLog.setEchoLevel(ccpWarn);

    ccpApi.core.initCCP(ccpContainer.current, {
      ccpUrl: 'https://silk-helix.my.connect.aws/ccp-v2/softphone', // instance URL
      region: 'eu-west-2',
      loginPopup: true,
      loginPopupAutoClose: true,
      loginOptions: {
        autoClose: true,
        height: 600,
        width: 400,
      },
      softphone: {
        allowFramedSoftphone: true,
      },
      pageOptions: {
        enableAudioDeviceSettings: true,
        enablePhoneTypeSettings: false,
      },
    });

    let nextContactIndex = 1;
    ccpApi.contact(safeEventHandler((contact) => {
      const contactIndex = nextContactIndex;
      nextContactIndex += 1;

      setContacts((previousContacts) => {
        const newContacts = new Map(previousContacts);
        newContacts.set(contactIndex, {
          queueName: contact.getQueue().name,
          phoneNumber: getCustomerPhoneNumber(contact),
        });
        return newContacts;
      });

      const contactDebug = (contact) => `state=${contact.getState().type}, queue=${contact.getQueue().name}`;

      console.log(`connect.contact(${contactIndex}): ${contactDebug(contact)}\nAttributes: ${JSON.stringify(contact.getAttributes())}`);

      contact.onIncoming(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onIncoming: ${contactDebug(contact)}`);
      }));

      contact.onRefresh(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onRefresh: ${contactDebug(contact)}`);
      }));

      contact.onAccepted(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onAccepted: ${contactDebug(contact)}`);
      }));

      contact.onEnded(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onEnded: ${contactDebug(contact)}`);
      }));

      contact.onConnected(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onConnected: ${contactDebug(contact)}`);
      }));

      contact.onDestroy(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onDestroy: ${contactDebug(contact)}`);

        setContacts((previousContacts) => {
          const newContacts = new Map(previousContacts);
          newContacts.delete(contactIndex);
          return newContacts;
        });
      }));

      contact.onACW(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onACW: ${contactDebug(contact)}`);
      }));

      contact.onError(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onError: ${contactDebug(contact)}`);
      }));

      contact.onMissed(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onMissed: ${contactDebug(contact)}`);
      }));

      contact.onConnecting(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onConnecting: ${contactDebug(contact)}`);
      }));

      contact.onPending(safeEventHandler((contact) => {
        console.log(`\tcontact(${contactIndex}).onPending: ${contactDebug(contact)}`);
      }));
    }));

    return function cleanUp() {
      bodyClassNames.forEach((className) => document.body.classList.remove(className));

      // Cleanup CCP, based on:
      // https://github.com/amazon-connect/amazon-connect-streams/blob/HEAD/Documentation.md#connectcoreterminate

      try {
        ccpApi.core.terminate();
      } catch (error) {
        console.log(`Error terminating up CCP: ${error}`);
      }

      try {
        const containerElement = ccpContainer.current;
        if (!containerElement) {
          throw new Error('No element to clean up.');
        }
        while (containerElement.firstChild) {
          containerElement.removeChild(containerElement.firstChild);
        }
      } catch (error) {
        console.log(`Error cleanup up DOM after CCP: ${error}`);
      }
    };
  }, []); // empty array arg here tells useEffect only to fire once, like componentDidMount used to

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-sm" ref={ccpContainer} style={{ height: '600px' }} />
        <div className="col-sm px-2 py-1 bg-light">
          <ContactsView contacts={contacts} />
        </div>
      </div>
      <p className="mt-3 mx-2">
        If you&apos;re getting &quot;refused to connect&quot;, or a sad face image, above then try
        &nbsp;<a href="https://silk-helix.my.connect.aws/ccp-v2/softphone" target="_blank" rel="noreferrer">this direct link</a>&nbsp;
        to the CCP to get yourself logged in. You can then close that window, come back to this
        window and refresh.
      </p>
    </div>
  );
}

/**
 * An event handler.
 * @callback eventHandler
 * @param {*} event The argument accepted by this handler.
 */

/**
 * Wraps an 'unsafe' handler function with try/catch, to be presented to third-party APIs that callback to us.
 * We have found that the Amazon CCP callback code does not appear to protect itself from errors thrown by our handlers.
 * @param {eventHandler} handler The 'unsafe' callback handler which is to be wrapped.
 * @returns A function that should be presented to the third-party API as our callback handler.
 */
function safeEventHandler(handler) {
  return (event) => {
    try {
      handler(event);
    } catch (error) {
      console.log(`Error when handling CCP event: ${error}`);
    }
  };
}

function getCustomerPhoneNumber(contact) {
  /*

  We have a block in our preamble module in our Amazon Connect workflows that sets a user defined
  attribute on contacts to 'Customer number' System Attribute ($.CustomerEndpoint.Address), with
  the name `customerPhoneNumber`. See:
  https://docs.aws.amazon.com/connect/latest/adminguide/connect-attrib-list.html

  This comes into us here as an object with this layout (string form rendered for this comment
  using JSON.stringify(contact.getAttributes())):

  {
    "customerPhoneNumber": {
      "name":"customerPhoneNumber",
      "value":"+447778011839"
    }
  }

  */

  const object = contact.getAttributes().customerPhoneNumber;
  if (!object) {
    console.log('WARN: Contact attributes does not include customerPhoneNumber property.');
    return null;
  }

  const { value } = object;
  // TODO this appears not to be instanceof String ... ??
  if (!value) {
    console.log('WARN: customerPhoneNumber property does not have a value.');
    return null;
  }

  console.log(`Customer Phone Number: "${value}"`);
  return value;
}

function ContactView(props) {
  const { contact } = props;

  return (
    <div className="border border-dark rounded py-1 px-2 my-2">
      {contact.phoneNumber}<br />
      Queue: <strong>{contact.queueName}</strong>
    </div>
  );
}

function ContactsView(props) {
  const { contacts } = props;
  if (contacts.size > 0) {
    const items = [];
    contacts.forEach((contact) => {
      items.push(<ContactView contact={contact} />);
    });
    return <div>{items}</div>;
  }
  return <p>There are no active contacts.</p>;
}

ContactView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contact: PropTypes.any.isRequired,
};

ContactsView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contacts: PropTypes.any.isRequired,
};

export default Component;
