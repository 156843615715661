/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import ModifierInformation from './ModifierInformation';
import ErrorList, { prependError } from './ErrorList';

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: null,

      // Both notes and contacts are arrays of QueryDocumentSnapshot objects.
      // https://firebase.google.com/docs/reference/js/firebase.firestore.QueryDocumentSnapshot
      notes: null,
      contacts: null,

      errors: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { customerId } = match.params; // from Route

    const documentReference = firebase.firestore().collection('customers').doc(customerId);
    documentReference.get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          this.setState({
            documentData: documentSnapshot.data(),
          });
        } else {
          this.setState((state) => ({
            errors: prependError(state.errors, new Error(`Record not found for customerId "${customerId}".`)),
          }));
        }
      })
      .catch((error) => this.setState((state) => ({
        errors: prependError(state.errors, error),
      })));

    documentReference.collection('notes').get()
      .then((querySnapshot) => this.setState({
        notes: querySnapshot.docs,
      }))
      .catch((error) => this.setState((state) => ({
        errors: prependError(state.errors, error),
      })));

    documentReference.collection('contacts').get()
      .then((querySnapshot) => this.setState({
        contacts: querySnapshot.docs,
      }))
      .catch((error) => this.setState((state) => ({
        errors: prependError(state.errors, error),
      })));
  }

  render() {
    const { match } = this.props;
    const { customerId } = match.params; // from Route
    const {
      documentData,
      notes,
      contacts,
      errors,
    } = this.state;

    if (!documentData) {
      if (errors.length > 0) {
        // Probably a final state. Example being unknown customer id.
        return <div className="container"><ErrorList errors={errors} /></div>;
      }

      // Intermediate state, which should not last very long.
      return <div className="container"><p>Loading customer with id {customerId}.</p></div>;
    }

    // Filter the notes into one of three types.
    // This allows us to do some basic grouping on the page.
    // It's important that none of the groups contain notes that are in any of the other groups.
    const haveNotes = notes && notes.length > 0;
    const alertNotes = haveNotes ? notes.filter((notesDoc) => notesDoc.data().category === 'alert') : [];
    const topNotes = haveNotes ? notes.filter((notesDoc) => notesDoc.data().category === 'service') : [];
    // eslint-disable-next-line max-len
    const bottomNotes = haveNotes ? notes.filter((notesDoc) => notesDoc.data().category !== 'alert' && notesDoc.data().category !== 'service') : [];

    return (
      <div className="container">
        <h2>{documentData.name}</h2>
        {
          alertNotes.length > 0
            ? (
              <div>
                <h3 className="text-danger">Alerts / Important Information</h3>
                <NotesView customerId={customerId} documentSnapshots={alertNotes} />
              </div>
            )
            : null
        }
        <div className="row">
          <div className="col">
            <h3>Service Details</h3>
            <NotesView customerId={customerId} documentSnapshots={topNotes} />
          </div>
          <div className="col">
            <h3>Contacts</h3>
            <ContactsView customerId={customerId} documentSnapshots={contacts} />
            <div>
              <Link className="btn btn-primary my-2" to={`/customer/${customerId}/contact/add`}>Add Contact</Link>
            </div>
          </div>
        </div>

        <Link className="btn btn-primary my-2" to={`/customer/${customerId}/note/add`}>Add Customer Note</Link>
        <NotesView customerId={customerId} documentSnapshots={bottomNotes} />
        <Link className="btn btn-primary my-2" to={`/customer/${customerId}/note/add`}>Add Customer Note</Link>
      </div>
    );
  }
}

/**
 * Returns null if there is no header required.
 */
function categoryHeader(category) {
  switch (category) {
    case 'advice':
      return <div className="bg-info text-white px-2 py-1 font-weight-bold">Advice</div>;

    case 'service':
      return <div className="bg-success text-white px-2 py-1 font-weight-bold">Service Details</div>;

    case 'contact':
      return <div className="bg-secondary text-white px-2 py-1">Contact Information</div>;

    case 'alert':
      return <div className="bg-danger text-white px-2 py-1 font-weight-bold">Alert / Important</div>;

    default: return null; // no heading, including 'general'
  }
}

function optionalRender(value, renderer) {
  return value ? renderer(value) : null;
}

function NoteView(props) {
  const { customerId, documentSnapshot } = props;
  const documentId = documentSnapshot.id;
  const data = documentSnapshot.data();
  return (
    <div className="border border-dark rounded p-0 my-2">
      {categoryHeader(data.category)}
      <div className="m-2 d-flex flex-column">
        <div className="sh-display-linebreaks">
          {data.text}
        </div>
        <div className="d-flex justify-content-between align-items-end mt-3">
          <Link
            className="btn btn-primary btn-sm"
            to={`/customer/${customerId}/note/edit/${documentId}`}
          >
            Edit Note
          </Link>
          <ModifierInformation
            className="text-right small"
            created={data.created}
            modified={data.modified}
          />
        </div>
      </div>
    </div>
  );
}

function NotesView(props) {
  const { customerId, documentSnapshots } = props;
  if (documentSnapshots && documentSnapshots.length > 0) {
    const items = documentSnapshots.map((documentSnapshot) => (
      <NoteView key={documentSnapshot.id} customerId={customerId} documentSnapshot={documentSnapshot} />
    ));
    return <div>{items}</div>;
  }
  return <p>There are no notes for this customer.</p>;
}

function ContactView(props) {
  const { customerId, documentSnapshot } = props;
  const documentId = documentSnapshot.id;
  const data = documentSnapshot.data();

  return (
    <div className="border border-dark rounded p-0 my-2">
      {categoryHeader(data.category)}
      <div className="m-2 d-flex flex-column">
        <div className="row">
          <div className="col">
            <div><strong>{data.displayName}</strong></div>
            { optionalRender(data.jobTitle, (jobTitle) => <div><i>{jobTitle}</i></div>) }
            { optionalRender(data.email, (email) => <div><a href={`mailto:${email}`}>{email}</a></div>) }
            { optionalRender(data.primaryPhone, (phone) => <div>☎ Primary: <a href={`tel:${phone}`}>{phone}</a></div>) }
            { optionalRender(data.secondaryPhone, (phone) => <div>☎ Secondary: <a href={`tel:${phone}`}>{phone}</a></div>) }
            <div className="sh-display-linebreaks">
              {data.notes}
            </div>
          </div>
          <div className="col">
            <div>{data.isAuthorised ? '✅' : '❌'} Authorised</div>
            <div>{data.isPrimary ? '✅' : '❌'} Primary</div>
            <div>{data.isAccounts ? '✅' : '❌'} Accounts</div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end mt-3">
          <Link
            className="btn btn-primary btn-sm"
            to={`/customer/${customerId}/contact/edit/${documentId}`}
          >
            Edit Contact
          </Link>
          <ModifierInformation
            className="text-right small"
            created={data.created}
            modified={data.modified}
          />
        </div>
      </div>
    </div>
  );
}

function ContactsView(props) {
  const { customerId, documentSnapshots } = props;
  if (documentSnapshots && documentSnapshots.length > 0) {
    const items = documentSnapshots.map((documentSnapshot) => (
      <ContactView key={documentSnapshot.id} customerId={customerId} documentSnapshot={documentSnapshot} />
    ));
    return <div>{items}</div>;
  }
  return <p>There are no contacts for this customer.</p>;
}

/**
 * The fact that I need to disable react/forbid-prop-types here tells me that
 * we may need to consider moving to TypeScript at some point, as clearly this
 * loosely coupled approach is frowned upon by ESLint.
 *
 * see: https://github.com/ReactTraining/react-router/issues/6177#issuecomment-392388990
 */
Customer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

NoteView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customerId: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documentSnapshot: PropTypes.any.isRequired,
};

NotesView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customerId: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documentSnapshots: PropTypes.any,
};
NotesView.defaultProps = {
  documentSnapshots: null,
};

ContactView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customerId: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documentSnapshot: PropTypes.any.isRequired,
};

ContactsView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customerId: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documentSnapshots: PropTypes.any,
};
ContactsView.defaultProps = {
  documentSnapshots: null,
};

export default Customer;
