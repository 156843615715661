/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  useHistory, // if we upgrade React Router then we'll use useNavigate instead
} from 'react-router-dom';
import firebase from 'firebase/app';
import { addSubscription } from './api';

// using the technique called "Controlled Components":
//   https://reactjs.org/docs/forms.html#controlled-components
// Also styling using pure Bootstrap CSS:
//   https://getbootstrap.com/docs/4.6/components/forms/

const freeAdviceCallCollectorId = 'freeAdviceCall';

function Component(props) {
  const { match } = props;
  const { emailAddress } = match.params; // from Route

  const history = useHistory();
  const [searching, setSearching] = useState(true);
  const [document, setDocument] = useState(undefined); // null means 'not found'

  const [freeAdviceCollectorState, setFreeAdviceCollectorState] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [apiError, setApiError] = useState(null);

  const doSearch = async () => {
    const snapshot = await firebase.firestore()
      .collection('email addresses')
      .doc(emailAddress)
      .get();

    if (snapshot.exists) {
      setDocument(snapshot.data());
    } else {
      setDocument(null);
    }
    setSearching(false);
  };

  // empty array arg here tells useEffect only to fire once, like componentDidMount used to
  useEffect(doSearch, []);

  function reset() {
    history.push('/email');
  }

  function submitToFreeAdviceCallCollector() {
    setFreeAdviceCollectorState('dataEntry');
  }

  function onFirstNameInputChange(event) {
    event.preventDefault();
    setFirstName(event.target.value);
  }

  async function onSubmitToFreeAdviceCollectorFormSubmit(event) {
    event.preventDefault();

    setFreeAdviceCollectorState('submitting');
    setApiError(null);

    try {
      await addSubscription(firstName, emailAddress, freeAdviceCallCollectorId);
      setFreeAdviceCollectorState(null);
      setSearching(true);
      await doSearch();
    } catch (error) {
      setApiError(error);
      setFreeAdviceCollectorState('dataEntry');
    }
  }

  if (freeAdviceCollectorState) {
    const submitting = (freeAdviceCollectorState === 'submitting');
    const failedElement = apiError
      ? (<div className="text-danger"><strong>Error:</strong> {apiError.message}</div>)
      : null;
    return (
      <div className="container">
        <h3>{emailAddress}</h3>
        <p>Submit to <strong>Free Advice Call</strong> follow-up collector:</p>
        <form key="form" onSubmit={onSubmitToFreeAdviceCollectorFormSubmit}>
          <div className="form-group">
            <input
              autoFocus
              value={firstName}
              onChange={onFirstNameInputChange}
              className="form-control"
              aria-describedby="firstNameHelp"
              disabled={submitting}
            />
            <small id="firstNameHelp" className="form-text text-muted">
              <strong>First names are required.</strong>
              They help our emails look friendlier.
              The idea is that once we have had a free advice call with someone
              then we are definitely going to know their first name.
            </small>
          </div>
          <button
            className="btn btn-primary my-2"
            type="submit"
            disabled={submitting}
          >
            {
              submitting
                ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-nowrap">Submitting...</div>
                    <div className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" />
                  </div>
                )
                : 'Submit to Collector'
            }
          </button>
          {failedElement}
        </form>
      </div>
    );
  }

  if (searching) {
    return (
      <div className="container">
        <h3>{emailAddress}</h3>
        <p>Searching...</p>
      </div>
    );
  }

  if (document !== undefined) {
    // document can be null here, meaning 'not found'
    const isLegacyDocument = (document && document.subscription !== undefined);
    return (
      <div className="container">
        <h3>{emailAddress}</h3>
        {isLegacyDocument ? <p className="text-danger">The database record for this email address has at least one field present that indicates that it was created prior to the changes to our way of storing subscriptions that were made during the weekend of 19 March 2022.</p> : null}
        {isLegacyDocument ? <OldSubscriptionView document={document} /> : null}
        <SubmissionsView document={document} />
        <div className="mt-2 border-top pt-2">
          <button
            type="button"
            className="btn btn-primary btn-small"
            onClick={submitToFreeAdviceCallCollector}
            disabled={!!findDocumentSubmissionForCollector(document, freeAdviceCallCollectorId)}
          >
            Submit to <strong>Free Advice Call</strong> follow-up collector...
          </button>
        </div>
        <div className="mt-5 border-top border-dark pt-3">
          <button
            type="button"
            className="btn btn-primary btn-small"
            onClick={reset}
          >
            Find Another Email Address
          </button>
        </div>
      </div>
    );
  }
}

function SubmissionsView(props) {
  const { document } = props;
  if (!document) {
    return <p className="font-italic">No document.</p>;
  }
  const { submissions } = document;
  if (!submissions || submissions.length < 1) {
    return <p className="font-italic">No submissions.</p>;
  }

  const rows = submissions.map((submission) => (
    <tr key={submission.collectorId}>
      <th scope="row">{submission.collectorId}</th>
      <td>{submission.firstName ?? <i>Not specified</i>}</td>
      <td>{submission.subscriptionId}</td>
    </tr>
  ));

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Collector</th>
          <th scope="col">First Name</th>
          <th scope="col">Subscription</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

function OldSubscriptionView(props) {
  const { document } = props;
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Field</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">First Name</th>
          <td>{document.firstName ?? <i>Not specified</i>}</td>
        </tr>
        <tr>
          <th scope="row">Subscription</th>
          <td>{document.subscription}</td>
        </tr>
      </tbody>
    </table>
  );
}

function findDocumentSubmissionForCollector(document, collectorId) {
  if (!document) return false;
  const { submissions } = document;
  if (!submissions || submissions.length < 1) return false;
  return submissions.find((submission) => submission.collectorId === collectorId);
}

export default Component;
