/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const pillStyle = 'border border-info rounded py-0 px-1 my-0 ml-1 mr-0';

// eslint-disable-next-line no-unused-vars
const Component = function (props, context) {
  const { modified, created, className } = props;
  return (
    <div className={className}>
      <div className="d-flex">
        { modified
          ? (
            <div className={pillStyle}>
              Modified by {modified.user.displayName}, {formatTimestamp(modified.timestamp)}
            </div>
          )
          : null }
        { created
          ? (
            <div className={pillStyle}>
              Created by {created.user.displayName}, {formatTimestamp(created.timestamp)}
            </div>
          )
          : null }
      </div>
    </div>
  );
};

const formatter = new Intl.DateTimeFormat(
  'en-GB',
  { dateStyle: 'short', timeStyle: 'short' },
);

function formatTimestamp(timestamp) {
  return formatter.format(new Date(timestamp));
}

Component.propTypes = {
  modified: PropTypes.object,
  created: PropTypes.object,
  className: PropTypes.string,
};

Component.defaultProps = {
  modified: null,
  created: null,
  className: null,
};

export default Component;
