import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';

const tableStyles = {
  color: 'rgb(0,0,0)',
  'font-family': 'sans-serif',
  'font-size': '16px',
};

const column1Styles = {
  'padding-right': '16px',
  'text-align': 'right',
  'vertical-align': 'middle',
};

const column2Styles = {
  padding: '9px 18px',
  'background-color': 'rgb(0,128,117)',
  'border-radius': '12px',
};

const closingParagraphStyles = {
  'font-family': 'sans-serif',
  'border-top': '1px solid rgb(221,221,221)',
  'padding-top': '10px',
  'font-size': '12px',
  color: 'rgb(102,102,102)',
};

const openingDivStyles = {
  'padding-bottom': '10px',
};

const textLinkStyles = {
  color: 'rgb(0,128,117)',
};

const personNameStyles = {
  'font-family': 'sans-serif',
  'font-size': '14px',
  'font-weight': 'bold',
};

const personNameSuffixStyles = {
  'font-family': 'sans-serif',
  'font-size': '12px',
  color: 'rgb(102,102,102)',
  'padding-left': '3px',
};

const personTitleStyles = {
  'font-family': 'sans-serif',
  'font-size': '12px',
  color: 'rgb(102,102,102)',
};

const smallerTextStyles = {
  'font-family': 'sans-serif',
  'font-size': '12px',
};

const logoStyles = {
  'max-width': '100%',
  'max-height': '100%',
  height: '60px',
  width: 'auto',
  border: '0px',
  'font-family': 'sans-serif',
};

const defaultCopyMessage = 'Press to copy this footer to your clipboard.';

function Component() {
  const [copyMessage, setCopyMessage] = useState(defaultCopyMessage);
  const [userSpecific, setUserSpecific] = useState({});
  const { displayName, uid } = firebase.auth().currentUser;

  useEffect(() => {
    const documentReference = firebase.firestore().collection('users').doc(uid);
    documentReference.get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const {
            linkedInProfileUrl,
            displayNameQualificationsSuffix,
            jobTitle,
          } = documentSnapshot.data();
          setUserSpecific({
            linkedInProfileUrl,
            displayNameQualificationsSuffix,
            jobTitle,
          });
        } else {
          setUserSpecific({ error: `Record not found for uid "${uid}".` });
        }
      })
      .catch((error) => {
        setUserSpecific({ error: `Failed to get user information: ${error}` });
      });
  }, []); // empty array arg here tells useEffect only to fire once, like componentDidMount used to

  function copyButtonHandler() {
    const parent = document.getElementById('emailFooterContainer');
    if (!parent) {
      // eslint-disable-next-line no-alert
      alert('Failed to find parent element.');
      return;
    }
    const { innerHTML } = parent;
    if (!innerHTML) {
      // eslint-disable-next-line no-alert
      alert('Failed to find inner HTML.');
      return;
    }

    // copy both text and HTMLObject to clipboard
    // this lets us paste rich-text for WYSIWYG editors
    // https://developer.mozilla.org/en-US/docs/Web/API/ClipboardEvent/clipboardData#browser_compatibility

    function listener(copyEvent) {
      copyEvent.clipboardData.setData('text/html', innerHTML);
      copyEvent.clipboardData.setData('text/plain', innerHTML);
      copyEvent.preventDefault();
    }

    document.addEventListener('copy', listener);
    const execResult = document.execCommand('copy');
    document.removeEventListener('copy', listener);

    if (!execResult) {
      // eslint-disable-next-line no-alert
      alert('Failed to execute copy command.');
      return;
    }

    setCopyMessage('Copied to clipboard.');
    setTimeout(() => {
      setCopyMessage(defaultCopyMessage);
    }, 1000);
  }

  return (
    <div className="container">
      <h3>Email Footer</h3>
      <div className="d-flex my-3">
        <button type="button" className="footer-copy" onClick={copyButtonHandler}>Copy</button>
        <div className="ml-2">{copyMessage}</div>
      </div>
      <div id="emailFooterContainer" style={{ 'background-color': '#fff', padding: '10px', border: '4px solid #999' }}>
        <div style={openingDivStyles}>
          <table cellPadding="0" cellSpacing="0" style={tableStyles}>
            <tbody>
              <tr>
                <td valign="middle" style={column1Styles}>
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td valign="top" align="right">
                          <span style={personNameStyles}>{displayName}</span>
                          { userSpecific.displayNameQualificationsSuffix
                            ? <span style={personNameSuffixStyles}>&nbsp;MA, Chartered MCIPD</span>
                            : null }
                          <br />
                          <span style={personTitleStyles}>
                            { userSpecific.jobTitle ? `${userSpecific.jobTitle}, ` : null }
                            Silk Helix Ltd
                          </span>
                          <br />
                          <span style={smallerTextStyles}>01245 910 500</span>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" align="right">
                          <span style={smallerTextStyles}>
                            <a
                              href={userSpecific.linkedInProfileUrl || 'https://linkedin.com/company/silkhelix'}
                              style={textLinkStyles}
                              target="_blank"
                              rel="noreferrer"
                            >
                              linkedin
                            </a>
                            <span>&nbsp;/&nbsp;</span>
                            <a href="https://www.youtube.com/channel/UCY1nzkzfQcBSAD-BG7cohOg" style={textLinkStyles} target="_blank" rel="noreferrer">youtube</a>
                            <span>&nbsp;/&nbsp;</span>
                            <a href="https://fb.me/SilkHelix" style={textLinkStyles} target="_blank" rel="noreferrer">facebook</a>
                            <span>&nbsp;/&nbsp;</span>
                            <a href="https://www.instagram.com/silkhelix/" style={textLinkStyles} target="_blank" rel="noreferrer">instagram</a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td valign="middle" style={column2Styles}>
                  <a href="https://www.silkhelix.co.uk/" target="_blank" rel="noreferrer">
                    <img alt="Silk Helix logo" height="60" border="0" src="https://i.silkhelix.co.uk/logo-w-120h.png" style={logoStyles} />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={closingParagraphStyles}>
          The information contained in this email and any attached file is confidential and is intended solely for the addressee.
          <br />
          If you are not the addressee, please do not disclose, copy, distribute or use the contents.
          <br />
          If you have received this mail in error we would appreciate it if you could notify us and then delete it.
        </p>
      </div>
      <p className="mt-3">
        Notes:
      </p>
      <ul>
        <li>The name in the above footer is taken from your Google account display name.</li>
        { userSpecific.error
          ? <li>There was an error retrieving your user information: <strong>${userSpecific.error}</strong></li>
          : null }
        { userSpecific.displayNameQualificationsSuffix
          ? null
          : <li>Your user does not have a qualifications suffix configured (only some people have this).</li> }
        { userSpecific.linkedInProfileUrl
          ? null
          : <li>Your user does not have a LinkedIn profile URL configured.</li> }
        { userSpecific.jobTitle
          ? null
          : <li>Your user does not have a job title configured.</li> }
      </ul>
    </div>
  );
}

export default Component;
