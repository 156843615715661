import React from 'react';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      error: null,
    };
  }

  componentDidMount() {
    firebase.firestore().collection('customers').get()
      .then((querySnapshot) => this.setState({
        documents: querySnapshot.docs,
        error: null,
      }))
      .catch((error) => this.setState({
        error,
      }));
  }

  render() {
    const { documents, error } = this.state;

    if (error) {
      return (
        <div className="container">
          <p>
            There was an error:
            {error.message}
            .
          </p>
        </div>
      );
    }

    if (documents.length === 0) {
      return <div className="container"><p>Customers will appear here.</p></div>;
    }

    const listItems = documents.map((doc) => (
      <li key={doc.id}>
        <Link to={`/customer/${doc.id}`}>
          <span className="mr-2">{doc.id}</span>
          <span>{doc.data().name}</span>
        </Link>
      </li>
    ));

    // React's render() API has relatively recently allowed the return of an
    // array of elements, per:
    // https://pawelgrzybek.com/return-multiple-elements-from-a-component-with-react-16/
    // I could also use React.Fragment but haven't read in to whether that somehow
    // brings some advantages. This seems logical enough.
    // UPDATE: Having said that, I've had to add keys to these elements to stop warnings
    // coming from React at runtime - so perhaps Fragment is the way to go!?
    return (
      <div className="container">
        <h3>Customers</h3>
        <ul>{listItems}</ul>
        <Link className="btn btn-primary my-2" to="/customer/add">Add Customer</Link>
      </div>
    );
  }
}

export default Component;
