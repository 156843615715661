/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

function Component(props) {
  const { errors } = props;
  return (
    <div className="text-danger">
      <h3>Errors:</h3>
      <ul>
        { errors.map((error, index) => (<li key={`error${index}`}>{error.message}</li>)) }
      </ul>
    </div>
  );
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array.isRequired,
};

export default Component;

/**
 * Returns a copy of the given array with the error added.
 * If the given error is not an Error instance then replaces it with an actual Error instance.
 *
 * If the given array is null then a new array is created. This allows calling code
 * to have an `errors` state that is `null` until at least one error exists to put in it.
 *
 * @param {array} array The existing array of zero or more error instances.
 * @param {any} error The error to be inserted at the beginning of the array.
 */
export function prependError(array, error) {
  const item = error instanceof Error
    ? error
    : new Error(`Error with unknown type: ${error.constructor.name}`);
  return array ? [item, ...array] : [item];
}
