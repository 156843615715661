/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { createCustomer } from './data';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      creating: false,
      number: null,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onInputChange(event) {
    this.setState({
      nameValue: event.target.value,
    });
  }

  async onFormSubmit(event) {
    event.preventDefault();
    const { nameValue } = this.state;
    this.setState({
      creating: true,
    });

    // TODO work out why there is an IDE error on this line.
    // despite the warning on the await here:
    //   'await' has no effect on the type of this expression.ts(80007)
    // the await is absolutely required! if we drop the await and just use this:
    //   const customerNumber = createCustomer(nameValue);
    // then customerNumber is a Promise!
    // the warning may relate to this function being marked async but with no error handling.
    const customerNumber = await createCustomer(nameValue);

    this.setState({
      creating: false,
      number: customerNumber,
    });
  }

  render() {
    const { nameValue, creating, number } = this.state;

    if (number) {
      return <Redirect to={`/customer/${number}`} />;
    }

    // using the technique called "Controlled Components":
    //   https://reactjs.org/docs/forms.html#controlled-components
    // Also styling using pure Bootstrap CSS:
    //   https://getbootstrap.com/docs/4.6/components/forms/
    return (
      <div className="container">
        <h3 key="title">Add Customer</h3>
        <form key="form" onSubmit={this.onFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Company / Organisation Name:</label>
            <input
              value={nameValue}
              onChange={this.onInputChange}
              className="form-control"
              id="name"
              aria-describedby="nameHelp"
              disabled={creating}
            />
            <small id="nameHelp" className="form-text text-muted">
              This form will automatically assign a customer number,
              but it does not check if we already have a customer with this name.
            </small>
          </div>
          <input className="btn btn-primary my-2" type="submit" value="Create New Customer" disabled={creating} />
          {creating && <div>Creating...</div>}
        </form>
      </div>
    );
  }
}

export default Component;
