/* eslint-disable import/prefer-default-export */

import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore();
const customersGlobalDocumentReference = db.collection('globals').doc('customers');
const customersCollectionReference = db.collection('customers');

/**
 * Creates a new customer.
 *
 * @param {string} name The company or organisation name.
 * @returns {number} The new customer number.
 */
export async function createCustomer(name) {
  return db.runTransaction(async (transaction) => {
    // Step 1: Read
    const snapshot = await transaction.get(customersGlobalDocumentReference);
    if (!snapshot.exists) {
      throw new Error(`Document does not exist at ${customersGlobalDocumentReference}.`);
    }

    // Step 2: Logic
    const newCustomerNumber = snapshot.data().nextId;

    // Step 3: Make Changes
    transaction.update(customersGlobalDocumentReference, {
      nextId: (newCustomerNumber + 1),
    });
    transaction.set(customersCollectionReference.doc(newCustomerNumber.toString()), {
      name,
    });

    // Step 4 (Checks) and 5 (Commit) happen outside of this code block.
    // This means that if the checks fail then this code block will be run again.
    return newCustomerNumber;
  });
}

/**
 * Returns the uid of the currently signed in user.
 */
export function currentUserId() {
  return firebase.auth().currentUser.uid;
}

/**
 * Returns the document reference to be used to access a customer's root document.
 * This is just a reference so the customer document may not yet exist at that location.
 *
 * A reason we have this function available is to avoid the need to import firebase
 * into all the component source files.
 *
 * @param {string} customerId The customer identifier / number.
 */
export function customerReference(customerId) {
  return db.collection('customers').doc(customerId);
}
