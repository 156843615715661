import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ErrorList, { prependError } from './ErrorList';
import { currentUserId, customerReference } from './data';

class Component extends React.Component {
  constructor(props) {
    super(props);

    // Bindings are essential here.
    this.save = this.save.bind(this);
    this.create = this.create.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

    // Capture the document reference here as we use it in numerous places.
    // Building these references is synchronous, so this is fine from that point of view.
    // Also, it means the match.params inspection is not duplicated everywhere either.
    const { match } = this.props;
    const { customerId, customerNoteId, verb } = match.params; // from Route

    const customerDocumentReference = customerReference(customerId);
    const needToLoad = verb !== 'add';

    this.state = {
      // values in the form - see https://reactjs.org/docs/forms.html#controlled-components
      textValue: '',
      categoryValue: 'general',
      isLoading: needToLoad, // only true very early on in lifecycle
      isNew: !needToLoad,
      needsToSave: false,
      isSaving: false,
      submitError: null,

      documentReference: needToLoad && customerDocumentReference.collection('notes').doc(customerNoteId),
      errors: null,
      customerName: null,
      customerId: null,
      customerDocumentReference,
    };
  }

  componentDidMount() {
    const { documentReference, customerDocumentReference } = this.state;
    if (documentReference) {
      // Load existing notes (verb wasn't 'add')
      documentReference.get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
            const d = documentSnapshot.data();
            this.setState({
              textValue: d.text,
              categoryValue: d.category,
              isLoading: false,
            });
          } else {
            this.setState((state) => ({
              errors: prependError(state.errors, new Error(`Customer Note record not found: ${documentReference.path}.`)),
              isLoading: false,
            }));
          }
        })
        .catch((error) => this.setState((state) => ({
          errors: prependError(state.errors, error),
        })));
    }

    // Load customer information.
    customerDocumentReference.get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          this.setState({
            customerId: documentSnapshot.id,
            customerName: documentSnapshot.data().name,
          });
        } else {
          this.setState((state) => ({
            errors: prependError(state.errors, new Error(`Customer record not found: ${customerDocumentReference.path}.`)),
          }));
        }
      })
      .catch((error) => this.setState((state) => ({
        errors: prependError(state.errors, error),
      })));
  }

  onTextAreaChange(event) {
    event.preventDefault();
    this.setState({
      textValue: event.target.value,
      needsToSave: true,
    });
  }

  onSelectChange(event) {
    event.preventDefault();
    this.setState({
      categoryValue: event.target.value,
      needsToSave: true,
    });
  }

  async onFormSubmit(event) {
    event.preventDefault();
    const { isNew } = this.state;

    this.setState({
      isSaving: true,
      submitError: null,
    });

    try {
      if (isNew) {
        await this.create();
      } else {
        await this.save();
      }
    } catch (error) {
      this.setState({
        isSaving: false,
        submitError: error,
      });
    }
  }

  async save() {
    const { documentReference, textValue, categoryValue } = this.state;

    await documentReference.update({
      text: textValue,
      category: categoryValue,
      modifyingUid: currentUserId(),
    });

    // We only do this when we have succeeded the operation.
    // This is because the catch in onFormSubmit also does a setState for final state.
    this.setState({
      isSaving: false,
      needsToSave: false,
    });
  }

  async create() {
    const { customerDocumentReference, textValue, categoryValue } = this.state;
    const collectionReference = customerDocumentReference.collection('notes');

    // Assigns a document id automatically.
    // https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#add
    const documentReference = await collectionReference.add({
      text: textValue,
      category: categoryValue,
      creatingUid: currentUserId(),
    });

    // We only do this when we have succeeded the operation.
    // This is because the catch in onFormSubmit also does a setState for final state.
    this.setState({
      documentReference,
      isSaving: false,
      needsToSave: false,
      isNew: false,
    });
  }

  render() {
    const {
      textValue,
      categoryValue,
      isNew,
      isLoading,
      isSaving,
      needsToSave,
      customerId,
      customerName,
      errors,
      submitError,
    } = this.state;

    if (errors) {
      return <div className="container"><ErrorList errors={errors} /></div>;
    }

    if (isLoading) {
      return <div className="container"><p>Loading note...</p></div>;
    }

    let customerElement = <h2>Loading customer data...</h2>;
    if (customerName) {
      customerElement = (
        <h2>
          <Link to={`/customer/${customerId}`}>
            {customerName}
          </Link>
        </h2>
      );
    }

    return (
      <div className="container">
        {customerElement}
        <form key="form" onSubmit={this.onFormSubmit}>
          <textarea
            name="text"
            className="form-control my-2"
            rows="10"
            value={textValue}
            onChange={this.onTextAreaChange}
            disabled={isSaving}
          />
          <select name="category" className="form-control my-2" value={categoryValue} onChange={this.onSelectChange} disabled={isSaving}>
            <option value="general">General / Misc.</option>
            <option value="advice">Advice</option>
            <option value="service">Service Details</option>
            <option value="contact">Contact Information</option>
            <option value="alert">Alert / Important</option>
          </select>
          <div className="d-flex">
            <button type="submit" disabled={!needsToSave || isSaving} className="btn btn-primary mr-auto">
              {isNew ? 'Create Note' : 'Save Note'}
            </button>
            <div>
              {isSaving && <span className="font-weight-bold">Saving...</span>}
              {needsToSave && !isSaving && <span className="text-danger font-weight-bold">not saved</span>}
              <span className="text-muted ml-2">{isNew ? 'NEW' : 'EDIT'}</span>
            </div>
          </div>
        </form>
        {submitError ? <p className="text-danger"><strong>Error:</strong> {submitError.message}</p> : null}
      </div>
    );
  }
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default Component;
