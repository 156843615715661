import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import './App.scss';

import Auth, { SignInComponent } from './Auth';
import AddCustomer from './AddCustomer';
import CustomerList from './CustomerList';
import Customer from './Customer';
import NoteEditor from './NoteEditor';
import InvalidRoute from './InvalidRoute';
import ContactEditor from './ContactEditor';
import AmazonCCP from './AmazonConnectContactControlPanel';
import EmailFooter from './EmailFooter';
import Email from './Email';
import EmailLookup from './EmailLookup';

// Not going to switch this on yet.
// - Google had switched it on in the snippet of code I copied from the Firebase Console.
// - But the snippet of code for FirebaseUI Web React didn't, so I'm deferring to that for now.
// https://github.com/firebase/firebaseui-web-react#using-styledfirebaseauth-with-a-redirect
// firebase.analytics();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleSignedInChange = this.handleSignedInChange.bind(this);
    this.state = {
      signedIn: false,
    };
  }

  handleSignedInChange(signedIn) {
    this.setState({ signedIn });
  }

  render() {
    const { signedIn } = this.state;

    // Our use of Route uses the component attribute, which is "old fashioned".
    // see: https://reacttraining.com/react-router/web/api/Route/route-render-methods
    //
    // The recommended method is to use children elements, leveraging hooks.
    // But I couldn't work out how they sat with ES6 classes - and, perhaps, they don't at all.
    // to quote: "They let you use state and other React features without writing a class."
    // from: https://reactjs.org/docs/hooks-intro.html
    // Which, essentially, seems to be a strong boot towards getting people to make their code more modern.
    // But I do find that a little bit comical given ES6 classes are also (relatively) new technology!
    // So, perhaps, before I consider refactoring to be more modern I should also consider TypeScript.
    // Also, I've added InvalidRoute but am not entirely convinced it ever gets seen.
    const bodyElement = signedIn
      ? (
        <Switch key="bodyElement">
          <Route path="/ccp" component={AmazonCCP} />
          <Route path="/emailFooter" component={EmailFooter} />
          <Route path="/email/:emailAddress" component={Email} />
          <Route path="/email" component={EmailLookup} />

          <Route path="/customer/add" component={AddCustomer} />
          <Route path="/customer/:customerId/note/:verb/:customerNoteId?" component={NoteEditor} />
          <Route path="/customer/:customerId/contact/:verb/:targetId?" component={ContactEditor} />
          <Route path="/customer/:customerId" component={Customer} />

          <Route path="/" component={CustomerList} />

          <Route component={InvalidRoute} />
        </Switch>
      ) : <SignInComponent />;

    // Based on: https://getbootstrap.com/docs/4.6/components/navbar/#supported-content
    // The Link component is supplied by React Router.
    // We apply the correct bootstrap CSS classes to that to get the right look.
    return [
      <nav key="pageHeader" className="navbar navbar-expand-sm navbar-dark bg-primary">
        <Link to="/" className="navbar-brand">
          <div className="d-flex align-items-center">
            <div className="mr-1">
              <img style={{ height: '1.75em' }} src="/logow.svg" alt="Silk Helix" />
            </div>
            <div>
              <strong>CRM</strong>
            </div>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/asdasd" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                Tools
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/ccp" className="dropdown-item">
                  Phone System (Amazon CCP)
                </Link>
                <div className="dropdown-divider" />
                <Link to="/" className="dropdown-item">
                  Customer List (Home)
                </Link>
                <Link to="/email" className="dropdown-item">
                  Email Address (Find / Add)
                </Link>
              </div>
            </li>
            <li className="nav-item d-flex flex-column justify-content-center ml-2 border rounded-lg px-1">
              <small className="text-white text-center" style={{ 'line-height': '95%' }}>
                {process.env.NODE_ENV}
                {process.env.REACT_APP_GIT_SHA ?? null}
                <br />
                {process.env.REACT_APP_DATE ?? 'testing'}
              </small>
            </li>
          </ul>
        </div>

        <ul className="navbar-nav">
          <Auth onSignedInChange={this.handleSignedInChange} />
        </ul>
      </nav>,
      bodyElement,
    ];
  }
}

export default App;
