/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';

// Web App Firebase configuration for "CRM"
const firebaseConfig = {
  apiKey: 'AIzaSyAgkAfLnERQMeev6CvCcPq9r4AmARIv67E',
  authDomain: 'silk-helix.firebaseapp.com',
  databaseURL: 'https://silk-helix.firebaseio.com',
  projectId: 'silk-helix',
  storageBucket: 'silk-helix.appspot.com',
  messagingSenderId: '988976434173',
  appId: '1:988976434173:web:620fa29cb07cb98708e794',
  measurementId: 'G-Q0N1GCLJEM',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/**
 * https://github.com/firebase/firebaseui-web#configuration
 */
const uiConfig = {
  signInFlow: 'redirect',
  // signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function signOutClickHandler(event) {
  event.preventDefault();
  firebase.auth().signOut();
}

/**
 * Based on Google's broken example at:
 * https://github.com/firebase/firebaseui-web-react#using-firebaseauth-with-local-state
 */
class Component extends React.Component {
  constructor(props) {
    super(props);

    // I need my onAuthStateChanged method to be able to use this as it
    // has to mutate state. See:
    // https://reactjs.org/docs/handling-events.html
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);

    this.state = {
      isSignedIn: false,
    };
  }

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  onAuthStateChanged(user) {
    this.setState({ isSignedIn: !!user });
    const { onSignedInChange } = this.props;
    onSignedInChange(user);
  }

  render() {
    const { isSignedIn } = this.state;
    return (
      <li className="nav-item dropdown">
        <a
          role="button"
          href="#"
          className="nav-link dropdown-toggle"
          id="navbarAuthDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          { isSignedIn ? firebase.auth().currentUser.displayName : 'Not Authenticated' }
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarAuthDropdown">
          <Link to="/emailFooter" className="dropdown-item">
            Email Footer
          </Link>
          <div className="dropdown-divider" />
          <a role="button" href="#" className="dropdown-item" onClick={signOutClickHandler}>
            Sign Out
          </a>
        </div>
      </li>
    );
  }
}

Component.propTypes = {
  onSignedInChange: PropTypes.func.isRequired,
};

export default Component;

export function SignInComponent() {
  return (
    <StyledFirebaseAuth
      uiConfig={uiConfig}
      firebaseAuth={firebase.auth()}
    />
  );
}
