/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  useHistory, // if we upgrade React Router then we'll use useNavigate instead
} from 'react-router-dom';

// using the technique called "Controlled Components":
//   https://reactjs.org/docs/forms.html#controlled-components
// Also styling using pure Bootstrap CSS:
//   https://getbootstrap.com/docs/4.6/components/forms/

function Component() {
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');

  function onEmailAddressInputChange(event) {
    event.preventDefault();
    setEmailAddress(event.target.value);
  }

  async function onSearchFormSubmit(event) {
    event.preventDefault();
    history.push(`/email/${emailAddress}`);
  }

  const trimmedEmailAddress = emailAddress.trim();
  const isFindable = trimmedEmailAddress.length > 7
    && trimmedEmailAddress.includes('@');
  return (
    <div className="container">
      <h3>Email Addresses</h3>
      <form key="form" onSubmit={onSearchFormSubmit}>
        <div className="form-group">
          <label htmlFor="emailAddressInput">Email Address to Find:</label>
          <input
            autoFocus
            id="emailAddressInput"
            value={emailAddress}
            onChange={onEmailAddressInputChange}
            className="form-control"
            aria-describedby="emailAddressHelp"
          />
          <small id="emailAddressHelp" className="form-text text-muted">
            This will only find an exact match to the entered address,
            ignoring whether letters are upper or lower case
            (i.e. case-insensitive).
          </small>
        </div>
        <input className="btn btn-primary my-2" type="submit" value="Find" disabled={!isFindable} />
      </form>
    </div>
  );
}

export default Component;
