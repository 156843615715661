/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { validate, ValidatorResultError } from 'jsonschema';

import ErrorList, { prependError } from './ErrorList';
import { currentUserId, customerReference } from './data';
import schema from './schemas/contact.json';

const collectionName = 'contacts';

class Component extends React.Component {
  constructor(props) {
    super(props);

    // Bindings are essential here.
    this.save = this.save.bind(this);
    this.create = this.create.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    // Capture the document reference here as we use it in numerous places.
    // Building these references is synchronous, so this is fine from that point of view.
    // Also, it means the match.params inspection is not duplicated everywhere either.
    const { match } = this.props;
    const { customerId, targetId, verb } = match.params; // from Route

    const customerDocumentReference = customerReference(customerId);
    const needToLoad = verb !== 'add';

    this.state = {
      // values in the form - see https://reactjs.org/docs/forms.html#controlled-components
      fields: {
        firstName: '',
        lastName: '',
        displayName: '',
        isDisplayNameSynthesized: true,
        jobTitle: null,
        email: null,
        primaryPhone: null,
        secondaryPhone: null,
        notes: null,
        isPrimary: false,
        isAccounts: false,
        isAuthorised: false,
      },

      isLoading: needToLoad, // only true very early on in lifecycle
      isNew: !needToLoad,
      needsToSave: false,
      isSaving: false,
      submitError: null, // human-readable description of reason for failure to submit

      documentReference: needToLoad && customerDocumentReference.collection(collectionName).doc(targetId),
      errors: null,
      customerName: null,
      customerId: null,
      customerDocumentReference, // the document that owns this contact
    };
  }

  componentDidMount() {
    const { documentReference, customerDocumentReference } = this.state;
    if (documentReference) {
      // Load existing contact (verb wasn't 'add')
      documentReference.get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
            const d = documentSnapshot.data();
            this.setState({
              fields: {
                firstName: conformRequiredString(d.firstName),
                lastName: conformRequiredString(d.lastName),
                displayName: conformRequiredString(d.displayName),
                isDisplayNameSynthesized: conformRequiredBoolean(d.isDisplayNameSynthesized),
                jobTitle: conformNullable(d.jobTitle),
                email: conformNullable(d.email),
                primaryPhone: conformNullable(d.primaryPhone),
                secondaryPhone: conformNullable(d.secondaryPhone),
                notes: conformNullable(d.notes),
                isPrimary: conformRequiredBoolean(d.isPrimary),
                isAccounts: conformRequiredBoolean(d.isAccounts),
                isAuthorised: conformRequiredBoolean(d.isAuthorised),
              },
              isLoading: false,
            });
          } else {
            this.setState((state) => ({
              errors: prependError(state.errors, new Error(`Customer Contact record not found: ${documentReference.path}.`)),
              isLoading: false,
            }));
          }
        })
        .catch((error) => this.setState((state) => ({
          errors: prependError(state.errors, error),
        })));
    }

    // Load customer information.
    customerDocumentReference.get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          this.setState({
            customerId: documentSnapshot.id,
            customerName: documentSnapshot.data().name,
          });
        } else {
          this.setState((state) => ({
            errors: prependError(state.errors, new Error(`Customer record not found: ${customerDocumentReference.path}.`)),
          }));
        }
      })
      .catch((error) => this.setState((state) => ({
        errors: prependError(state.errors, error),
      })));
  }

  onChange(event) {
    const { target } = event;
    const { name, value } = target;
    const isToggle = (target.type === 'checkbox');
    const isFirstName = (name === 'firstName');
    const isLastName = (name === 'lastName');
    const isName = isFirstName || isLastName;

    // TODO derive from schema
    const isNullable = (name === 'jobTitle' || name === 'email' || name === 'primaryPhone' || name === 'secondaryPhone' || name === 'notes');

    // I've tested and this method only gets called with name 'displayName' when the
    // mutation was made by the user, NEVER by this synthesis. Which is great.
    const isDisplayName = (name === 'displayName');

    this.setState((state) => {
      // Special logic to support synthesizing display name from first and last names.
      const isDisplayNameSynthesized = state.fields.isDisplayNameSynthesized && !isDisplayName;
      const synthesizeDisplayName = isName && isDisplayNameSynthesized;
      const firstName = isFirstName ? value : state.fields.firstName;
      const lastName = isLastName ? value : state.fields.lastName;
      let displayName = isDisplayName ? value : state.fields.displayName;
      displayName = synthesizeDisplayName ? `${firstName} ${lastName}` : displayName;

      // Special logic to support checkbox controls and also textual input boxes which should populate
      // the data with null in the case that nothing has been entered.
      let conformedValue;
      if (isToggle) {
        conformedValue = !state.fields[name];
      } else if (isNullable) {
        // only pass value thru if it's non-empty, otherwise null.
        conformedValue = (value === '') ? null : value;
      } else {
        // pass value thru, even if it is an empty string.
        conformedValue = value;
      }

      return {
        // https://stackoverflow.com/a/43041334/392847
        fields: {
          ...state.fields,
          [name]: conformedValue,
          displayName,
          isDisplayNameSynthesized,
        },
        needsToSave: true,
      };
    });
  }

  async onFormSubmit(event) {
    event.preventDefault();
    const { isNew } = this.state;

    // pre-operation set state (balances the post-operation below)
    this.setState({
      isSaving: true,
      submitError: null,
    });

    // the operation
    let submitError = null;
    const extraState = { };
    try {
      if (isNew) {
        extraState.documentReference = await this.create();
      } else {
        await this.save();
      }
    } catch (error) {
      if (error instanceof ValidatorResultError) {
        // https://www.npmjs.com/package/jsonschema#results
        submitError = '';
        error.errors.forEach((validatorError) => {
          submitError += `${validatorError.schema.title}: ${validatorError.message}.\n`;
        });
      } else {
        submitError = error.toString();
      }
    }

    // post-operation set state (balances the pre-operation above)
    const didFail = (submitError !== null);
    this.setState({
      isSaving: false,
      needsToSave: didFail,
      isNew: isNew && didFail,
      submitError,
      ...extraState, // for documentReference when adding a new contact
    });
  }

  async save() {
    const {
      documentReference,
      fields,
    } = this.state;

    throwIfInvalid(fields);

    await documentReference.update({
      modifyingUid: currentUserId(),
      ...fields,
    });
  }

  /**
   * Adds a new contact record to the Firestore, for this customer.
   * @returns Document reference for the new contact.
   */
  async create() {
    const {
      customerDocumentReference,
      fields,
    } = this.state;

    throwIfInvalid(fields);

    const collectionReference = customerDocumentReference.collection(collectionName);

    // Assigns a document id automatically.
    // https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#add
    return collectionReference.add({
      creatingUid: currentUserId(),
      ...fields,
    });
  }

  render() {
    const {
      fields,
      isNew,
      isLoading,
      isSaving,
      needsToSave,
      customerId,
      customerName,
      errors,
      submitError,
    } = this.state;

    if (errors) {
      return <div className="container"><ErrorList errors={errors} /></div>;
    }

    if (isLoading) {
      return <div className="container"><p>Loading contact...</p></div>;
    }

    let customerElement = <h2>Loading customer data...</h2>;
    if (customerName) {
      customerElement = (
        <h2>
          <Link to={`/customer/${customerId}`}>
            {customerName}
          </Link>
        </h2>
      );
    }

    return (
      <div className="container">
        {customerElement}
        <form key="form" onSubmit={this.onFormSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstNameInput">{schema.properties.firstName.title}:</label>
              <input
                className="form-control"
                name="firstName"
                id="firstNameInput"
                disabled={isSaving}
                onChange={this.onChange}
                value={fields.firstName}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastNameInput">{schema.properties.lastName.title}:</label>
              <input
                className="form-control"
                name="lastName"
                id="lastNameInput"
                disabled={isSaving}
                onChange={this.onChange}
                value={fields.lastName}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="displayNameInput">{schema.properties.displayName.title}:</label>
            <input
              value={fields.displayName}
              onChange={this.onChange}
              className="form-control"
              id="displayNameInput"
              name="displayName"
              aria-describedby="displayNameHelp"
              disabled={isSaving}
            />
            <small id="displayNameHelp" className="form-text text-muted">
              If you don&apos;t modify this field then we will automatically fill in &quot;[{schema.properties.firstName.title}] [{schema.properties.lastName.title}]&quot; for you.
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="jobTitleInput">{schema.properties.jobTitle.title}:</label>
            <input
              value={conformRequiredString(fields.jobTitle)}
              onChange={this.onChange}
              className="form-control"
              id="jobTitleInput"
              name="jobTitle"
              disabled={isSaving}
            />
          </div>

          <div className="form-group">
            <label htmlFor="emailInput">{schema.properties.email.title}:</label>
            <input
              value={conformRequiredString(fields.email)}
              onChange={this.onChange}
              className="form-control"
              id="emailInput"
              name="email"
              type="email"
              disabled={isSaving}
            />
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="primaryPhoneInput">{schema.properties.primaryPhone.title}:</label>
              <input
                className="form-control"
                name="primaryPhone"
                id="primaryPhoneInput"
                disabled={isSaving}
                onChange={this.onChange}
                value={conformRequiredString(fields.primaryPhone)}
                type="tel"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="secondaryPhoneInput">{schema.properties.secondaryPhone.title}:</label>
              <input
                className="form-control"
                name="secondaryPhone"
                id="secondaryPhoneInput"
                disabled={isSaving}
                onChange={this.onChange}
                value={conformRequiredString(fields.secondaryPhone)}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="notesInput">{schema.properties.notes.title}:</label>
            <textarea
              id="notesInput"
              name="notes"
              className="form-control my-2"
              rows="10"
              value={conformRequiredString(fields.notes)}
              onChange={this.onChange}
              aria-describedby="notesHelp"
              disabled={isSaving}
            />
            <small id="notesHelp" className="form-text text-muted">
              Keep these brief. They are intended for quick reminders only, not biographies.
            </small>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isPrimaryInput"
              name="isPrimary"
              checked={fields.isPrimary}
              onChange={this.onChange}
            />
            <label className="form-check-label" htmlFor="isPrimaryInput">{schema.properties.isPrimary.title}</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isAccountsInput"
              name="isAccounts"
              checked={fields.isAccounts}
              onChange={this.onChange}
            />
            <label className="form-check-label" htmlFor="isAccountsInput">{schema.properties.isAccounts.title}</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isAuthorisedInput"
              name="isAuthorised"
              checked={fields.isAuthorised}
              onChange={this.onChange}
            />
            <label className="form-check-label" htmlFor="isAuthorisedInput">{schema.properties.isAuthorised.title}</label>
          </div>

          <div className="d-flex">
            <button type="submit" disabled={!needsToSave || isSaving} className="btn btn-primary mr-auto">
              {isNew ? 'Create Contact' : 'Save Contact'}
            </button>
            <div>
              {isSaving && <span className="font-weight-bold">Saving...</span>}
              {needsToSave && !isSaving && <span className="text-danger font-weight-bold">not saved</span>}
              <span className="text-muted ml-2">{isNew ? 'NEW' : 'EDIT'}</span>
            </div>
          </div>
        </form>
        {submitError ? <div className="text-danger my-3"><strong>Form Validation or Submission Error:</strong><br /><pre>{submitError}</pre></div> : null}
      </div>
    );
  }
}

// TODO move these conform functions to a utility class, which will allow them to be unit tested too.
// TODO consider inferring these from the JSON schema. Perhaps with formal subschemas for each class of field (e.g. nullable string vs required string).

// Covers the very specific scenario that there was no field for a key in the Firestore.
// TODO replace with type-specific function (e.g. conformNullableString).
function conformNullable(value) {
  return (value === undefined) ? null : value;
}

// TODO validate that this function replaces both `undefined` and `null` with `''`.
function conformRequiredString(value) {
  return value || '';
}

function conformRequiredBoolean(value, defaultValue) {
  return value ? !!value : !!defaultValue;
}

function throwIfInvalid(fields) {
  // https://www.npmjs.com/package/jsonschema
  validate(fields, schema, { throwAll: true });
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default Component;
